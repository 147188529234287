import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import {FormattedMessage} from "react-intl";
import AdventCalendar from "./components/advent-calendar/AdventCalendar";

const target = document.getElementById('page-advent-calendar');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

if(target) {
	ReactDOM.render(
		<BundleEntry
			bundleName="page-advent-calendar"
			locale={localeCode}
			prefetchTranslations={[
				'frontend.adventcalendar.select.design',
				'frontend.adventcalendar.winter.edition',
				'frontend.adventcalendar.winter.edition.beers',
				'frontend.adventcalendar.winter.edition.description',
				'frontend.adventcalendar.light.and.fruity.edition',
				'frontend.adventcalendar.light.and.fruity.beers',
				'frontend.adventcalendar.light.and.fruity.description',
				'frontend.adventcalendar.select.design.button',
				'frontend.adventcalendar.create',
				'frontend.adventcalendar.merry.christmas.type',
				'frontend.adventcalendar.merry.christmas.type.description',
				'frontend.adventcalendar.best.dad.type',
				'frontend.adventcalendar.best.dad.type.description',
				'frontend.adventcalendar.best.friends.type',
				'frontend.adventcalendar.best.friends.type.description',
				'frontend.adventcalendar.back',
				'frontend.adventcalendar.next',
				'frontend.adventcalendar.summary',
				'frontend.adventcalendar.summary.description',
				'frontend.adventcalendar.gift.title',
				'frontend.adventcalendar.recipient',
				'frontend.adventcalendar.recipient.placeholder',
				'frontend.adventcalendar.from.placeholder',
				'frontend.adventcalendar.to.placeholder',
				'frontend.adventcalendar.message.placeholder',
				'frontend.adventcalendar.from',
				'frontend.adventcalendar.to',
				'frontend.adventcalendar.message',
				'frontend.adventcalendar.gift.description',
				'frontend.adventcalendar.daily.greetings',
				'frontend.adventcalendar.yes.send.newsletter',
				'frontend.adventcalendar.yes.send.newsletter.disclaimer',
				'frontend.adventcalendar.no.send.newsletter',
				'frontend.adventcalendar.add.to.cart',
				'frontend.adventcalendar.cart.edit',
				'frontend.adventcalendar.save.to.cart',
				'frontend.adventcalendar.message.description',
				'adventcalendar.button.config.label'
			]}
		>
			<AdventCalendarWrapper/>
		</BundleEntry>,
		target
	);

}

function AdventCalendarWrapper() {

	const [isOpen, setIsOpen] = useState(false);

	return(
		<div>
			<div
				className={'tw-text-5xl tw-w-adventCalendarPageButton tw-font-bold tw-cursor-pointer tw-bg-black tw-p-8 tw-text-white tw-text-center hover:tw-bg-white hover:tw-text-black tw-border tw-border-black'}
				onClick={() => setIsOpen(true)}>
				<FormattedMessage id={'adventcalendar.button.config.label'} />
			</div>
			<AdventCalendar
				isOpen={isOpen}
				setIsOpen={setIsOpen} />
		</div>
	)

}